<template>
  <div class="serviceItems">
    <!-- header -->
    <homePage :urlFlag="6"></homePage>
    <!-- banner -->
    <div class="container-fluid bannerBox bannerImg">
      <img src="@/assets/img_joinus_banner.png" />
      <div>
        <div class="container">
          <h2>JOIN US</h2>
          <p>加入丝路里，开启新的未来</p>
        </div>
      </div>
    </div>
    <div class="container newsList">
      <div class="why">为什么选择丝路里</div>
      <div class="row">
        <div class="row" style="margin-bottom: 3em">
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_money"></span>
              <span>固定工资</span>
            </div>
            <p>
              根据工作岗位和个人能力为员工提供具有市场竞争力的固定工资，通常工作时间在八小时内，获得固定报酬的调整主要依据对人员的表现及领导评价的结果而定。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_trophy"></span>
              <span>绩效奖金</span>
            </div>
            <p>
              绩效奖金，也称一次性奖金。是根据员工的绩效考核结果给与的一次性奖励。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_money2"></span>
              <span>十三薪</span>
            </div>
            <p>
              十三薪及年底双薪，在年底为员工在本年度的辛苦付出给予肯定，按照在职时间给予。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_holiday"></span>
              <span>员工假期</span>
            </div>
            <p>
              懂得合理利用休息时间的员工，才能更好的为公司工作，周末双休，法定节假日，年休你值得拥有。
            </p>
          </div>
        </div>

        <!-- div4 -->
        <div class="row">
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_team"></span>
              <span>团队建设</span>
            </div>
            <p>
              有趣的灵魂比美丽的外表更优秀，良好的团队意识才能带我们走得更长远。孤军奋战，难敌千军万马，一年一次的团建，让我们更具有青春的活力。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_time"></span>
              <span>弹性工作制</span>
            </div>
            <p>
              多个岗位可以选择，完成规定的工作任务或固定的工作时间长度的前提下，可以灵活地、自主地选择工作的具体时间安排。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_money3"></span>
              <span>办公环境</span>
            </div>
            <p>
              公司位于高新区办公楼，交通便利，配套设施齐全，楼下就是网红打卡花园，一年四季花香四溢，空气宜人。
            </p>
          </div>
          <div class="col-md-3 col-sm-6 joinBox">
            <div class="joinTitle">
              <span class="icon font_family icon-icon_others"></span>
              <span>其他福利</span>
            </div>
            <p>
              除了为员工提供五险以外，公司会在节假日为员工准备节假日福利，工作日下午茶点，内部员工折扣等。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container newsList" v-if="false">
      <div class="why">在丝路里开启新的未来</div>
      <div class="category">
        <span class="allCategory">职位类别</span>
        <span
          :style="{ color: posiFlag == 0 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 0"
          >全部</span
        >
        <span
          :style="{ color: posiFlag == 1 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 1"
          >报关部</span
        >
        <span
          :style="{ color: posiFlag == 2 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 2"
          >销售部</span
        >
        <span
          :style="{ color: posiFlag == 3 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 3"
          >操作部</span
        >
        <span
          :style="{ color: posiFlag == 4 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 4"
          >仓储部</span
        >
        <span
          :style="{ color: posiFlag == 5 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 5"
          >宣传部</span
        >
        <span
          :style="{ color: posiFlag == 6 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 6"
          >财务部</span
        >
        <span
          :style="{ color: posiFlag == 7 ? '#D60B17' : '#757575' }"
          @click="posiFlag = 7"
          >行政部</span
        >
      </div>
      <div class="seletCate" @click.stop="posCloseFlag = !posCloseFlag">
        <span>职位类别</span>
        <span style="float: right">
          {{
            posiFlag == 0
              ? "全部"
              : posiFlag == 1
              ? "报关部"
              : posiFlag == 2
              ? "销售部"
              : posiFlag == 3
              ? "操作部"
              : posiFlag == 4
              ? "仓储部"
              : posiFlag == 5
              ? "宣传部"
              : posiFlag == 5
              ? "财务部"
              : "行政部"
          }}
          <span class="icon font_family icon-icon_enter"></span>
        </span>
        <ul
          class="seletBox"
          :style="{ 'max-height': posCloseFlag ? '265px' : '0' }"
        >
          <li
            :style="{ color: posiFlag == 0 ? '#D60B17' : '#757575' }"
            @click="seletPos(0)"
          >
            全部
          </li>
          <li
            :style="{ color: posiFlag == 1 ? '#D60B17' : '#757575' }"
            @click="seletPos(1)"
          >
            报关部
          </li>
          <li
            :style="{ color: posiFlag == 2 ? '#D60B17' : '#757575' }"
            @click="seletPos(2)"
          >
            销售部
          </li>
          <li
            :style="{ color: posiFlag == 3 ? '#D60B17' : '#757575' }"
            @click="seletPos(3)"
          >
            操作部
          </li>
          <li
            :style="{ color: posiFlag == 4 ? '#D60B17' : '#757575' }"
            @click="seletPos(4)"
          >
            仓储部
          </li>
          <li
            :style="{ color: posiFlag == 5 ? '#D60B17' : '#757575' }"
            @click="seletPos(5)"
          >
            宣传部
          </li>
          <li
            :style="{ color: posiFlag == 6 ? '#D60B17' : '#757575' }"
            @click="seletPos(6)"
          >
            财务部
          </li>
          <li
            :style="{ color: posiFlag == 7 ? '#D60B17' : '#757575' }"
            @click="seletPos(7)"
          >
            行政部
          </li>
        </ul>
      </div>
    </div>
    <div class="container allPosition" v-if="false">
      <div class="posiBox positionTop">
        <div>职位名称</div>
        <div>职位类别</div>
        <div>所属部门</div>
        <div></div>
      </div>
      <div v-for="(val, index) of posiList" :key="index">
        <div
          class="posiBox"
          @click="lookDetail(index)"
          :style="{ color: val.flag ? '#D60B17' : '#333333' }"
        >
          <div>{{ val.name }}</div>
          <div>{{ val.category }}</div>
          <div>{{ val.department }}</div>
          <div class="positionIcon">{{ val.flag ? "-" : "+" }}</div>
        </div>
        <div
          class="positionDetail"
          :style="{ 'max-height': val.flag ? '600px' : '0' }"
        >
          <div class="job-detail-font" v-if="val.name === '会计'">
            <p>
              岗位职责：<br />1、现金及银行收付处理，银行对账，根据审核后的单据，制作记账凭证；<br />2、公司员工每月的各类费用报销处理，根据原始凭证进行账务处理，准备和报送会计报表，监督按时完成各项涉税事项；<br />3、负责与相关部门沟通核对，确保成本核算的准确性，保证账证，账实相符；<br />4、财会文件的准备、归档和保管；<br />5、定期内部对账，督促、协助销售追回应收账款，避免坏账；<br />6、负责与银行、工商，税务等部门的对外联络；<br />7、向公司管理层提供各项财务报告和必要的财务分析。
            </p>
            <p>
              任职资格：<br />1、财会，经济等相关专业，全日制大专以上学历，持有初级会计师及以上资格证书；<br />2、3年以上相关工作经验；<br />3、熟悉企业一般纳税人的业务流程；<br />4、熟悉会计报表的处理，会计法规和税法；<br />5、良好的职业操守及团队合作精神，较强的沟通、理解和分析能力；<br />6、具有独立工作和学习的能力，工作认真细心；<br />7、熟悉物流、国际货代行业等优先。<br />8、年龄25岁-35岁优先考虑。
            </p>
            <p>
              请发送简历及作品至 na.sun@srleader.com <br />邮件主题：“职位+姓名”
            </p>
          </div>
          <div class="job-detail-font" v-if="val.name === '视频剪辑'">
            <p>
              岗位职责：<br />1、
              能熟练使用主流视频剪辑软件，负责公司宣传片、专题片等方面的项目（拍摄）、剪辑工作;<br />2、公司组织策划的推广活动，并对活动进行后期编辑；<br />3、协助网络营销人员进行视频的网络推广、宣传等工作。
              <br />4、负责剪辑和包装制作（剪辑为主）
              <br />5、理解电视语言，传达宣传诉求。<br />
              6、熟练使用pr、final
            </p>
            <p>
              请发送简历及作品至na.sun@srleader.com <br />邮件主题：“职位+姓名”
            </p>
            <!-- 
岗位职责： 1、 能熟练使用主流视频剪辑软件，负责公司宣传片、专题片等方面的项目（拍摄）、剪辑工作； 2、公司组织策划的推广活动，并对活动进行后期编辑； 3、协助网络营销人员进行视频的网络推广、宣传等工作。 4、负责剪辑和包装制作（剪辑为主） 5、理解电视语言，传达宣传诉求。 6、熟练使用pr、final 
cut、eduis等剪辑软件独立完成剪辑、配乐，会使用ae做简单特效处理，会使用ps做图像处理。（需带一份个人剪辑作品面试） 薪资4~6k
                         -->
          </div>
        </div>
      </div>
    </div>
    <afterbody></afterbody>
  </div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
  name: "serviceItems",
  components: {
    homePage,
    afterbody,
  },
  data() {
    return {
      posiFlag: 0,
      posCloseFlag: false,
      posiList: [
        {
          name: "会计",
          category: "财务",
          department: "财务部",
          flag: true,
        },
        {
          name: "视频剪辑",
          category: "宣传",
          department: "宣传部",
          flag: false,
        },
      ],
    };
  },
  methods: {
    seletPos(num) {
      this.posiFlag = num;
      this.posCloseFlag = false;
    },
    lookDetail(index) {
      // this.posiList.forEach((index)=> {
      //     index.flag = false
      // })
      // this.posiList[index].flag = true
      this.posiList[index].flag = !this.posiList[index].flag;
    },
    // 点击别的地方隐藏弹框
    closeAll() {
      this.posCloseFlag = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.querySelector("body").addEventListener("click", this.closeAll);
    });
  },
  onBeforeUnmount() {
    document
      .querySelector("body")
      .removeEventListener("click", this.handleBodyClick);
  },
};
</script>

<style scoped>
@import "joinus.css";
</style>
